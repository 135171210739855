<template>
  <q-page class="flex flex-center">
    <div style="min-height: 100vh;">
      <div class="q-mt-md q-mr-sm q-pa-md" style="max-width: 400px">
        <div v-if="!url">
          <q-item-label class="text-h6">
            You need to link your system first
          </q-item-label>
          <div class="q-mt-md q-mr-sm" v-if="scanner_active">
            <q-btn @click="scanner_active = false">
              Stop Scanner
            </q-btn>
            <qrcode-stream @detect="onDetect($event)" @decode="onDecode($event)" @init="onInit"></qrcode-stream>
          </div>
          <div class="q-mt-md q-mr-sm" v-if="!scanner_active">
            <q-item-label>
              Enter the URL of the system or click on the button to scan QR
            </q-item-label>
            <q-input @blur="updateUrl()" @change="updateUrl()" v-model="input_url" label="URL" filled></q-input>
            <q-btn class="q-mt-md q-mr-sm" @click="scanner_active = true">
              Start Scanner
            </q-btn>
          </div>
        </div>
        <div class="q-mt-md q-mr-sm" v-if="this.url">
          <q-img
              :src="logo_url"
              :height="'300px'"
              :fit="'contain'"
              style="object-fit: contain;"
          />
        </div>
        <q-form
            v-if="this.url"
            method="post" @submit.prevent="onSubmit"
            @reset="onReset"
            class="q-gutter-md"
        >

          <q-item-label>
            Cellphone
          </q-item-label>
          <q-input
              v-model="this.cellphone"
              v-maska data-maska="+## ## ### ####"
              type="text"
              name="cellphone"
              id="cellphone"
              class="dark:bg-gray-700 dark:text-gray-100 block w-full rounded-md border-0 py-1.5 dark:text-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />

          <q-input
              filled
              :type="isPwd ? 'password' : 'text'"
              hint="Please type password"
              name="password"
              v-model="this.password"
              label="Password"
              lazy-rules
              aria-autocomplete="current-password"
              :rules="[ val => val && val.length > 0 || 'Please type something']"
          >
              <template v-slot:append>
                <q-icon
                    :name="isPwd ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer"
                    @click="isPwd = !isPwd"
                ></q-icon>
              </template>
          </q-input>
          <div v-if="!has_accepted">
            <p>
              I accept the <span class="text-primary" style="pointer:click" @click="this.showToc()">license and terms</span>
            </p>
          <q-toggle v-model="this.accept" >
          <q-dialog v-model="this.showAcceptModal">
              <div style="background-color:white;max-height: 80vh; overflow-y: auto;">
                <t-o-c-page @close="this.showAcceptModal = false" v-if="this.showAcceptModal"></t-o-c-page>
              </div>

          </q-dialog>
          </q-toggle>
          </div>
          <div class="justify-center text-center items-center" style="height: 40px;">
            <q-btn label="Submit" type="submit" color="primary"></q-btn>
          </div>
          <div class="justify-center text-center items-center" style="height: 100px;">
            <q-btn label="Reset Password" @click="showResetPasswordModal()" type="button" color="black"></q-btn>
          </div>
          <div>
<!--            APPVERSION HERE-->
            <p class="text-center text-caption text-weight-thin">Powered by Blackwidow V2.25</p>
          </div>
        </q-form>

      </div>
    </div>
    <q-dialog v-model="resetPasswordModalVisible" persistent>
      <q-card class="dialog-card my-card bg-white text-gray">
        <div v-if="!this.otp_sent">
          <q-card-section class="q-pa-md">
            <div class="text-h6 q-my-lg">Reset login password</div>
            <q-item-label>
              Please confirm cellphone nr:
            </q-item-label>
            <q-input
                v-model="this.cellphone"
                v-maska data-maska="+## ## ### ####"
                type="text"
                name="cellphone"
                id="cellphone"
                class="dark:bg-gray-700 dark:text-gray-100 block w-full rounded-md border-0 py-1.5 dark:text-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />

          </q-card-section>
          <q-card-actions align="center" class="q-pa-md">
          <q-btn flat label="Cancel" color="grey" v-close-popup />
          <q-btn unelevated label="Send OTP" color="primary" @click="sendOTP()" />
        </q-card-actions>
        </div>
        <div v-if="this.otp_sent && !this.otp_confirmed">
          <q-card-section class="q-pa-md">
            <div class="text-h6 q-my-lg">Reset login password</div>
            <q-item-label>
              Please enter OTP:
            </q-item-label>
            <q-input
                v-model="this.otp"
                class="dark:bg-gray-700 dark:text-gray-100 block w-full rounded-md border-0 py-1.5 dark:text-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
          </q-card-section>
          <q-card-actions align="center" class="q-pa-md">
            <q-btn flat label="Cancel" color="grey" v-close-popup />
            <q-btn unelevated label="Confirm OTP" color="primary" @click="confirmOTP()" />
          </q-card-actions>
        </div>
        <div v-if="this.otp_sent && this.otp_confirmed">
          <q-card-section class="q-pa-md">
            <div class="text-h6 q-my-lg">Reset login password</div>
            <q-item-label>
              Please enter your new password
            </q-item-label>
            <q-input
                filled
                :type="isPwdOne ? 'password' : 'text'"
                hint="Please type password"
                name="password"
                v-model="this.password"
                label="Password"
                lazy-rules
                aria-autocomplete="current-password"
                :rules="[ val => val && val.length > 0 || 'Please type something']"
            >
              <template v-slot:append>
                <q-icon
                    :name="isPwdOne ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer"
                    @click="isPwdOne = !isPwdOne"
                ></q-icon>
              </template>
            </q-input>
            <q-input
                filled
                :type="isPwdTwo ? 'password' : 'text'"
                hint="Please confirm password"
                name="confirm_password"
                v-model="this.confirm_password"
                label="Confirm Password"
                lazy-rules
                :rules="[ val => val && val.length > 0 || 'Please type something']"
            >
              <template v-slot:append>
                <q-icon
                    :name="isPwdTwo ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer"
                    @click="isPwdTwo = !isPwdTwo"
                ></q-icon>
              </template>
            </q-input>
          </q-card-section>

          <q-card-actions align="center" class="q-pa-md">
            <q-btn flat label="Cancel" color="grey" v-close-popup />
            <q-btn unelevated label="Update password" color="primary" @click="updatePassword()" />
          </q-card-actions>
        </div>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<style>
</style>

<script>
import { useQuasar } from 'quasar';
import axios from 'axios'
import {useRoute, useRouter} from 'vue-router' // <- import useRoute here
import { QrcodeStream } from 'vue-qrcode-reader'
import {vMaska} from "maska/vue";
import TOCPage from "@/views/TOC.vue";
export default {
  name: 'PageLogin',
  directives:{
    maska : vMaska
  },
  components: {
    QrcodeStream,
    TOCPage,
  },
  computed: {
    url: function(){
      return this.$store.getters.url;
    },
    token: function () {
      return this.$store.getters.token;
    },
  },
  setup () {
    let quasar = useQuasar()
    const router = useRouter();
    return { quasar, router }
  },
  data(){
    return {
      otp_sent : false,
      otp_confirmed : false,
      confirm_password : '',
      otp: '',
      temp_user_id : null,
      has_accepted : false,
      showAcceptModal : false,
      isPwd: true,
      isPwdOne: true,
      isPwdTwo: true,
      logo_url : 'https://superadmin.blackwidow.org.za/customer_logo/specific',
      initial_url : null,
      input_url : null,
      resetPasswordModalVisible: false,
      scanner_active : false,
      cellphone : '+27',
      password : null,
      accept : false
    }
  },
  methods: {
    sendOTP(){
      axios.post(this.url+'/api/api-reset-password', {cellphone: this.cellphone.replace(/\s+/g, '')})
          .then(() => {
            this.quasar.notify({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'OTP sent successfully !!'
            })
            this.otp_sent = true;
          })
          .catch((error) => {
            this.quasar.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'OTP send failed'+error
            })
          })
          .finally(() => {
          });
    },
    updatePassword(){
        if(this.password != this.confirm_password){
            this.quasar.notify({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Passwords do not match'
            })
        }
      axios.post(this.url+'/api/api-update-password', {
        user_id: this.temp_user_id,
        otp : this.otp,
        password : this.password
      })
          .then((res) => {
            this.temp_user_id = res.data.user_id;
            this.quasar.notify({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Password updated successfully !!'
            })
            this.resetPasswordModalVisible = false;
          })
          .catch((error) => {
            this.quasar.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'OTP send failed'+error
            })
          })
          .finally(() => {
          });

    },
    confirmOTP(){
      axios.post(this.url+'/api/api-confirm-otp', {
        otp : this.otp,
      })
          .then((res) => {
            this.quasar.notify({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'OTP confirmed successfully !!'
            })
            this.temp_user_id = res.data.user_id;
            this.otp_sent = true;
          })
        this.otp_confirmed = true;
    },
    showResetPasswordModal(){
        this.resetPasswordModalVisible = true;
    },
    showToc(){
      this.showAcceptModal = true;
    },
    setupParameters() {
      const route = useRoute()
      if(route.params.url){
        this.input_url = route.params.url;
        this.$store.commit('UPDATE_URL',route.params.url);
      }
      this.has_accepted = this.$store.getters.user_terms;
      if(this.has_accepted){
        this.accept = true;
      }
    },
    onSubmit (evt) {
      console.log('@submit - do something here', evt)
      console.log(this.cellphone);
      console.log(this.password);
      console.log(this.accept);
      if (!this.accept) {
        this.quasar.notify({
          color: 'red-5',
          textColor: 'white',
          icon: 'warning',
          message: 'You need to accept the license and terms first'
        })
      }
      else {
        let submitData = {
          "cellphone" : this.cellphone.replace(/\s+/g, ''),
          "password" : this.password
        };
        let url = this.$store.getters.url;
        axios.post(url+'/api/login', submitData)
            .then((res) => {
              let token = res.data.token
              this.quasar.notify({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'Logged in successfully !!'
              })
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
              this.$store.commit('UPDATE_TOKEN',token);
              this.$store.commit('UPDATE_USER_ID',res.data.user_id);
              this.$store.commit('UPDATE_USER_NAME',res.data.user.name);
              this.$store.commit('UPDATE_USER_TERMS',res.data.user.accepted_responder_terms);
              this.$store.commit('UPDATE_USER_SURNAME',res.data.user.surname);
              localStorage.setItem( 'app_status', JSON.stringify('logged_in') );
              this.router.push({ name: "home"})
            })
            .catch((error) => {
              this.quasar.notify({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Log in Failed'+error
              })
            }).finally(() => {
          //Perform action in always
        });

      }
    },
    onReset () {
      this.cellphone = null
      this.password = null
      this.accept = false
    },
    resetUrl(){
      this.$store.commit('UPDATE_URL',null);
    },
    updateUrl(){
      this.$store.commit('UPDATE_URL',this.input_url);
    },
    onDetect(result) {
      this.$store.commit('UPDATE_URL',result[0].rawValue);
    },
    onDecode(result) {
      this.$store.commit('UPDATE_URL',result[0].rawValue);
    },
    onInit(promise) {
      promise.then(() => {
        console.log('QR code reader is ready!');
      }).catch(error => {
        if (error.name === 'NotAllowedError') {
          console.error('Permissions are not granted to use your camera');
        } else if (error.name === 'NotFoundError') {
          console.error('No camera on this device');
        }
        // Handle other initialization errors
      });
    }
  },
  mounted() {
    this.setupParameters();
    console.log(this.url);
  }
}
</script>
