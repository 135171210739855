import Vuex from "vuex";
import axios from "axios";
const store = new Vuex.Store({
    // Your store properties (state, mutations, actions, etc.)
    state: {
        unread_message_counter: 0, // Initialize the unread message counter
        open_dockets_counter: 0, // Initialize the unread message counter
        open_tasks_counter: 0, // Initialize the unread message counter
        url: process.env.VUE_APP_API_BASE_URL,
        user_id:null,
        user_name:null,
        user_surname:null,
        user_terms : null,
        level_one_in_use : false,
        level_two_in_use : false,
        level_three_in_use : false,
        one_description : null,
        two_description : null,
        three_description : null,
        four_description : null,
        five_description : null,
        docket_description : null,
        task_description : null,
        level_ones : [],
        level_twos : [],
        level_threes : [],
        level_fours : [],
        level_fives : [],
        docket_categories : [],
        task_categories : [],
        docket_sub_categories : [],
        current_level_one : null,
        current_level_two : null,
        current_level_three : null,
        current_level_four : null,
        current_level_five : null,
        token:null,
        online: true,
        lat:null,
        lng:null,
    },
    mutations: {
        UPDATE_UNREAD_MESSAGE_COUNTER(state, value) {
            state.unread_message_counter = value;
        },
        UPDATE_OPEN_DOCKETS_COUNTER(state, value) {
            state.open_dockets_counter = value;
        },
        UPDATE_OPEN_TASKS_COUNTER(state, value) {
            state.open_tasks_counter = value;
        },
        UPDATE_URL(state, value) {
            state.url = value;
        },
        UPDATE_USER_ID(state, value) {
            state.user_id = value;
        },
        UPDATE_USER_NAME(state, value) {
            state.user_name = value;
        },
        UPDATE_USER_TERMS(state, value) {
            state.user_terms = value;
        },
        UPDATE_USER_SURNAME(state, value) {
            state.user_surname = value;
        },
        UPDATE_TOKEN(state, value) {
            state.token = value;
        },
        UPDATE_ONLINE(state, value) {
            state.online = value;
        },
        UPDATE_LAT(state, value) {
            state.lat = value;
        },
        UPDATE_LNG(state, value) {
            state.lng = value;
        },
        UPDATE_LEVEL_ONE_IN_USE(state, value) {
            state.level_one_in_use = value;
        },
        UPDATE_LEVEL_TWO_IN_USE(state, value) {
            state.level_two_in_use = value;
        },
        UPDATE_LEVEL_THREE_IN_USE(state, value) {
            state.level_three_in_use = value;
        },
        UPDATE_LEVEL_ONES(state, value) {
            state.level_ones = value;
        },
        UPDATE_LEVEL_TWOS(state, value) {
            state.level_twos = value;
        },
        UPDATE_LEVEL_THREES(state, value) {
            state.level_threes = value;
        },
        UPDATE_LEVEL_FOURS(state, value) {
            state.level_fours = value;
        },
        UPDATE_LEVEL_FIVES(state, value) {
            state.level_fives = value;
        },
        UPDATE_CURRENT_LEVEL_ONE(state, value) {
            state.current_level_one = value;
        },
        UPDATE_CURRENT_LEVEL_TWO(state, value) {
            state.current_level_two = value;
        },
        UPDATE_CURRENT_LEVEL_THREE(state, value) {
            state.current_level_three = value;
        },
        UPDATE_CURRENT_LEVEL_FOUR(state, value) {
            state.current_level_four = value;
        },
        UPDATE_CURRENT_LEVEL_FIVE(state, value) {
            state.current_level_five = value;
            this.dispatch('getDocketCategories');
            this.dispatch('getTaskCategories');
        },
        UPDATE_ONE_DESCRIPTION(state, value) {
            state.one_description = value;
        },
        UPDATE_TWO_DESCRIPTION(state, value) {
            state.two_description = value;
        },
        UPDATE_THREE_DESCRIPTION(state, value) {
            state.three_description = value;
        },
        UPDATE_FOUR_DESCRIPTION(state, value) {
            state.four_description = value;
        },
        UPDATE_FIVE_DESCRIPTION(state, value) {
            state.five_description = value;
        },
        UPDATE_DOCKET_DESCRIPTION(state, value) {
            state.docket_description = value;
        },
        UPDATE_TASK_DESCRIPTION(state, value) {
            state.task_description = value;
        },
        UPDATE_TASK_CATEGORIES(state, value) {
            state.task_categories = value;
        },
        UPDATE_DOCKET_CATEGORIES(state, value) {
            state.docket_categories = value;
        },
        UPDATE_DOCKET_SUB_CATEGORIES(state, value) {
            state.docket_sub_categories = value;
        }
    },
    getters: {
        counter: state => state.unread_message_counter,
        docket_counter: state => state.open_dockets_counter,
        task_counter: state => state.open_tasks_counter,
        user_id: state => state.user_id,
        user_terms: state => state.user_terms,
        user_name: state => state.user_name,
        user_surname: state => state.user_surname,
        url: state => state.url,
        token: state => state.token,
        online: state => state.online,
        latitude: state => state.lat,
        longitude: state => state.lng,
        level_one_in_use: state => state.level_one_in_use,
        level_two_in_use: state => state.level_two_in_use,
        level_three_in_use: state => state.level_three_in_use,
        level_ones: state => state.level_ones,
        level_twos: state => state.level_twos,
        level_threes: state => state.level_threes,
        level_fours: state => state.level_fours,
        level_fives: state => state.level_fives,
        current_level_one: state => state.current_level_one,
        current_level_two: state => state.current_level_two,
        current_level_three: state => state.current_level_three,
        current_level_four: state => state.current_level_four,
        current_level_five: state => state.current_level_five,
        one_description: state => state.one_description,
        two_description: state => state.two_description,
        three_description: state => state.three_description,
        four_description: state => state.four_description,
        five_description: state => state.five_description,
        docket_description: state => state.docket_description,
        task_description: state => state.task_description,
        task_categories: state => state.task_categories,
        docket_categories: state => state.docket_categories,
        docket_sub_categories: state => state.docket_sub_categories
    },
    actions: {
        initializeStore({ state }) {
            // Check if the ID exists
            if (localStorage.getItem('AppStore')) {
                // Replace the state object with the stored item
                this.replaceState(
                    Object.assign(state, JSON.parse(localStorage.getItem('AppStore')))
                );
                if(this.state.token){
                    this.dispatch('getLevels');
                }
            }
        },
        // eslint-disable-next-line no-unused-vars
        updateUnreadMessageCounter({ state, commit }) {
            let user_id = state.user_id;
            if(user_id) {
                if (state.token !== null) {
                    try{
                        axios.get(this.state.url + '/api/api-unread-messages-counter', {
                            headers: {
                                Authorization: 'Bearer ' + this.state.token
                            }
                        }).then(response => {
                            this.commit('UPDATE_UNREAD_MESSAGE_COUNTER', response.data);
                        })
                    }catch (e) {
                        console.log(e);
                    }
                }
            }
        },
        updateOpenDocketCounter({ state }) {
            let user_id = state.user_id;
            if(user_id) {
                if (this.state.token !== null) {
                    try{
                        console.log('getting open dockets');
                        axios.get(this.state.url+'/api/api-dockets',{
                            headers:{
                                Authorization : 'Bearer '+state.token
                            },
                            params:{
                                owner_type : 'AssignedToMe',
                                participant_status: 'NotAccepted',
                                is_closed: false,
                            }
                        }).then(response => {
                            console.log('Received');
                            console.table(response.data);
                            this.commit('UPDATE_OPEN_DOCKETS_COUNTER', response.data.length);
                        });
                    }catch (e) {
                        console.log(e);
                    }
                }
            }
        },
        updateOpenTasksCounter({ state }) {
            let user_id = state.user_id;
            if(user_id) {
                if (state.token !== null) {
                    try{
                        axios.get(this.state.url+'/api/api-tasks', {
                            headers: {
                                'Authorization': 'Bearer ' + state.token
                            },
                            params: {
                                owner_type: 'UnacceptedByMe',
                                is_closed: false,
                            }
                        }).then(response => {
                            this.commit('UPDATE_OPEN_TASKS_COUNTER', response.data.total);
                        });
                    }catch (e) {
                        console.log(e);
                    }
                }
            }
        },
        getLevels(){
            axios.get(this.state.url + '/api/api-company-level-ones', {
                headers: {
                    Authorization: 'Bearer ' + this.state.token
                }
            }).then(response => {
                this.commit('UPDATE_LEVEL_ONES', response.data);
            })
            axios.get(this.state.url + '/api/api-company-level-twos', {
                headers: {
                    Authorization: 'Bearer ' + this.state.token
                }
            }).then(response => {
                this.commit('UPDATE_LEVEL_TWOS', response.data);
            })
            axios.get(this.state.url + '/api/api-company-level-threes', {
                headers: {
                    Authorization: 'Bearer ' + this.state.token
                }
            }).then(response => {
                this.commit('UPDATE_LEVEL_THREES', response.data);
            });
            axios.get(this.state.url + '/api/api-company-level-fours', {
                headers: {
                    Authorization: 'Bearer ' + this.state.token
                }
            }).then(response => {
                this.commit('UPDATE_LEVEL_FOURS', response.data);
            });
            axios.get(this.state.url + '/api/api-company-user-level-fives', {
                headers: {
                    Authorization: 'Bearer ' + this.state.token
                }
            }).then(response => {
                this.commit('UPDATE_LEVEL_FIVES', response.data);
            });
            axios.get(this.state.url + '/api/api-system', {
                headers: {
                    Authorization: 'Bearer ' + this.state.token
                }
            }).then(response => {
                this.commit('UPDATE_ONE_DESCRIPTION', response.data.company_level_1_description);
                this.commit('UPDATE_TWO_DESCRIPTION', response.data.company_level_2_description);
                this.commit('UPDATE_THREE_DESCRIPTION', response.data.company_level_3_description);
                this.commit('UPDATE_FOUR_DESCRIPTION', response.data.company_level_4_description);
                this.commit('UPDATE_FIVE_DESCRIPTION', response.data.company_level_5_description);
                this.commit('UPDATE_DOCKET_DESCRIPTION', response.data.docket_description);
                this.commit('UPDATE_TASK_DESCRIPTION', response.data.task_description);
                this.commit('UPDATE_LEVEL_ONE_IN_USE', response.data.level_1_in_use);
                this.commit('UPDATE_LEVEL_TWO_IN_USE', response.data.level_2_in_use);
                this.commit('UPDATE_LEVEL_THREE_IN_USE', response.data.level_3_in_use);
                this.dispatch('getDocketCategories');
                this.dispatch('getTaskCategories');
            });
        },
        getDocketCategories(){
            if(this.state.current_level_five){
                axios.get(this.state.url + '/api/api-docket-category', {
                    headers: {
                        Authorization: 'Bearer ' + this.state.token
                    },
                    params: {
                        company_level_five_id: this.state.current_level_five.id
                    }
                }).then(response => {
                    this.commit('UPDATE_DOCKET_CATEGORIES', response.data);
                });
            }
        },
        getTaskCategories(){
            if(this.state.current_level_five){
                axios.get(this.state.url + '/api/api-task-category', {
                    headers: {
                        Authorization: 'Bearer ' + this.state.token
                    },
                    params: {
                        company_level_five_id: this.state.current_level_five.id
                    }
                }).then(response => {
                    this.commit('UPDATE_TASK_CATEGORIES', response.data);
                });
            }
        },
        resetStore({commit}) {
            commit('UPDATE_USER_ID', null);
            commit('UPDATE_TOKEN', null);
            commit('UPDATE_USER_NAME', null);
            commit('UPDATE_USER_SURNAME', null);
            commit('UPDATE_ONLINE', true);
            commit('UPDATE_LAT', null);
            commit('UPDATE_LNG', null);
            commit('UPDATE_LEVEL_ONE_IN_USE', false);
            commit('UPDATE_LEVEL_TWO_IN_USE', false);
            commit('UPDATE_LEVEL_THREE_IN_USE', false);
            commit('UPDATE_LEVEL_ONES', []);
            commit('UPDATE_LEVEL_TWOS', []);
            commit('UPDATE_LEVEL_THREES', []);
            commit('UPDATE_LEVEL_FOURS', []);
            commit('UPDATE_LEVEL_FIVES', []);
            commit('UPDATE_CURRENT_LEVEL_ONE', null);
            commit('UPDATE_CURRENT_LEVEL_TWO', null);
            commit('UPDATE_CURRENT_LEVEL_THREE', null);
            commit('UPDATE_CURRENT_LEVEL_FOUR', null);
            commit('UPDATE_CURRENT_LEVEL_FIVE', null);
        },
        updateUserID({ commit }, value) {
            commit('UPDATE_USER_ID', value);
        }
    }
});

store.subscribe((mutation, state) => {
    // Store the state object as a JSON string
    console.log("Mutation Detected");
    localStorage.setItem('AppStore', JSON.stringify(state));
});

export default store;