<template>
  <q-page class="q-pa-md">
    <q-card>
      <div class="q-mt-md q-mb-md">
        <q-btn @click="this.$emit('close')" color="black" class="text-white">
          <q-icon name="close" size="xs"></q-icon> &nbsp;Close
        </q-btn>
      </div>
      <q-card-section>
        <div class="text-h6 text-center">End User License Agreement (EULA)</div>
        <div class="text-caption text-center">Effective Date: 2025-01-01 | Last Updated: 2025-01-01</div>
      </q-card-section>

      <q-separator></q-separator>

      <q-card-section>
        <div>
          <p>Thank you for choosing to use the applications provided by BlackWidow Cognitive Digital Solutions (Pty) Ltd ("BlackWidow," "we," "us," or "our"), a South African company. By downloading, installing, or using our applications under the brands bVigilant, Siyaleader, and AIMS (the "Apps"), you agree to be bound by the terms of this End User License Agreement ("Agreement"). If you do not agree to these terms, do not use the Apps.</p>

          <p><strong>1. Acceptance of Terms</strong></p>
          <p>By accessing or using the Apps, you confirm that you have read, understood, and agree to be bound by this Agreement, including any future modifications. If you do not agree to this Agreement, you must not use the Apps.</p>

          <p><strong>2. License Grant</strong></p>
          <p>BlackWidow grants you a limited, non-exclusive, non-transferable, revocable license to use the Apps solely for their intended purpose. This license is subject to your compliance with this Agreement and any other applicable terms.</p>

          <p><strong>3. User Obligations</strong></p>
          <ul>
            <li>Use the Apps only for lawful purposes and in accordance with this Agreement.</li>
            <li>Keep your login credentials secure and confidential.</li>
            <li>Ensure that any information provided by you is accurate, complete, and up to date.</li>
            <li>Refrain from reverse-engineering, modifying, or attempting to extract the source code of the Apps.</li>
            <li>Notify BlackWidow immediately of any unauthorized access to your account.</li>
          </ul>

          <p><strong>4. Prohibited Activities</strong></p>
          <ul>
            <li>Use the Apps for any illegal, harmful, or unauthorized purposes.</li>
            <li>Interfere with or disrupt the operation of the Apps or the servers and networks connected to the Apps.</li>
            <li>Use automated systems or tools (e.g., bots, crawlers) to access or use the Apps.</li>
          </ul>

          <p><strong>5. Intellectual Property Rights</strong></p>
          <p>All intellectual property rights in the Apps, including but not limited to software, designs, logos, trademarks, and content, are owned by or licensed to BlackWidow. Your use of the Apps does not grant you ownership of any intellectual property rights.</p>

          <p><strong>6. Privacy</strong></p>
          <p>Your use of the Apps is governed by our Privacy Policy, which explains how we collect, use, and share your information. By using the Apps, you agree to the terms of the Privacy Policy.</p>

          <p><strong>7. Disclaimers and Limitation of Liability</strong></p>
          <p>Disclaimers: The Apps are provided "as is" without warranties of any kind, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
          <p>Limitation of Liability: To the maximum extent permitted by law, BlackWidow is not liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Apps.</p>

          <p><strong>8. Termination</strong></p>
          <p>BlackWidow reserves the right to terminate or suspend your access to the Apps at any time, with or without notice, for any reason, including but not limited to your violation of this Agreement.</p>

          <p><strong>9. Governing Law</strong></p>
          <p>This Agreement is governed by and construed in accordance with the laws of the Republic of South Africa. Any disputes arising under or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts of South Africa.</p>

          <p><strong>10. Changes to this Agreement</strong></p>
          <p>BlackWidow may update or modify this Agreement from time to time. We will notify you of any significant changes by posting the updated Agreement in the Apps. Your continued use of the Apps after such changes constitutes your acceptance of the updated Agreement.</p>

          <p><strong>11. Contact Information</strong></p>
          <p>If you have any questions about this Agreement, please contact us at:</p>
          <p>BlackWidow Cognitive Digital Solutions (Pty) Ltd<br>Registration Number: 2019/473242/07<br>richard@blackwidow.org.za<br>Richard Hall</p>

          <p>By clicking "Accept" or using the Apps, you acknowledge that you have read, understood, and agreed to this End User License Agreement.</p>
        </div>
      </q-card-section>
      <q-separator></q-separator>
    </q-card>
  </q-page>
</template>

<script>
export default {
  name: "EndUserAgreementPage",
  emits: ['close'],
  methods:{
    goBack(){
      this.$router.go('-1');
    },
  }
};
</script>

<style scoped>
.q-page {
  max-width: 800px;
  margin: auto;
}
</style>
