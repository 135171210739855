<template>
  <q-list bordered class="clickable">
    <q-item>
      <q-item-section class="text-h5">{{returned_tasks.id}} - {{returned_tasks.description}}</q-item-section>
      <q-item-section avatar>
      </q-item-section>
    </q-item>
    <q-item v-if="Array.isArray(returned_tasks.allocatees)">
      <q-item-section v-if="returned_tasks.allocatees.length > 0">
        Task Allocated To: {{returned_tasks.allocatees[0].name}} {{returned_tasks.allocatees[0].surname}}
      </q-item-section>
    </q-item>
    <q-item>
      <q-btn :href="
      'https://www.google.com/maps/dir/?api=1&destination='+this.returned_tasks.gps_lat+','+this.returned_tasks.gps_lng+'&travelmode=driving'" target="_blank" color="primary" label="Get Directions">
      </q-btn>
    </q-item>
    <q-item v-if="(current_owner.accept === '1' && this.returned_tasks.status_id !=='2')">
      <q-btn @click="updateTaskStatus(2)"> Complete </q-btn>
    </q-item>
    <q-item v-if="current_owner.accept === '0'">
      <q-btn @click="acceptTask()"> Accept </q-btn>
    </q-item>
    <q-item v-if="current_owner.accept === '0'">
      <q-btn color="red" @click="rejectTask()"> Reject </q-btn>
    </q-item>
    <q-item>
      <q-item-section>Task Asignee: {{returned_tasks.assignee}}</q-item-section>
    </q-item>
    <q-item>
      <q-item-section>
        Due Date: {{returned_tasks.due_date}}
      </q-item-section>
    </q-item>
    <q-item>
      <q-item-section>
        Task Status: {{returned_tasks.status_name}}
      </q-item-section>
    </q-item>
    <q-item>
      <q-item-section>Task Type: {{returned_tasks.category_name}}</q-item-section>
    </q-item>
    <q-item>
      <q-item-section>Task Category: {{returned_tasks.kategory_name}}</q-item-section>
    </q-item>
    <q-item v-if="returned_tasks.sub_sub_category">
      <q-item-section>Task Sub Sub category: {{returned_tasks.sub_sub_category}}</q-item-section>
    </q-item>
    <q-item >
      <q-item-section>Log date: {{returned_tasks.created_at}}</q-item-section>
    </q-item>
  </q-list>
  <q-list bordered class="clickable">
    <q-item>
      <q-item-section class="text-h5">
        Members
      </q-item-section>
      <q-item-section side class="text-h5">
          <q-btn round icon="add" @click="referModalVisible = true"></q-btn>
      </q-item-section>
    </q-item>
    <q-item>
      <q-item-section avatar>
        <p v-for="(member,index) in returned_tasks['members']" :key="index">
          {{member.type}} : {{member.member}}
          <span v-if="member.task_owner_type_id==='3'">
            <q-btn round icon="delete" @click="removeMember(member)"></q-btn>
          </span>
        </p>
      </q-item-section>
    </q-item>
  </q-list>

  <q-dialog v-model="referModalVisible">
        <q-card>
            <q-card-section>
                <q-form>
                    <q-item-label>
                          User to Add
                    </q-item-label>
                    <q-select
                        v-model="selected_user"
                        :options="users"
                        option-label="name"
                        option-value="id"
                      ></q-select>
                </q-form>
            </q-card-section>
            <q-card-section>
              <q-btn class="bg-blue-5" @click="addUser">Submit</q-btn>
              <q-btn class="bg-red-5" @click="referModalVisible = false">Cancel</q-btn>
            </q-card-section>
        </q-card>
  </q-dialog>
  <q-dialog v-model="followerModalVisible">
    <q-card>
      <q-card-section>
        <q-form>
          <q-item-label>
            User to Add
          </q-item-label>
          <q-select
              v-model="selected_user"
              :options="users"
              option-label="name"
              option-value="id"
          ></q-select>
        </q-form>
      </q-card-section>
      <q-card-section>
        <q-btn class="bg-blue-5" @click="addUser">Submit</q-btn>
        <q-btn class="bg-red-5" @click="referModalVisible = false">Cancel</q-btn>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>


<script>

import axios from "axios";
import {useRouter} from "vue-router";
import {Notify} from "quasar";

export default {
  name: 'TaskBasic',
  emits: ['reload'],
  props: [
      'returned_tasks'
  ],
  data(){
    return {
      referModalVisible : false,
      followerModalVisible : false,
      users : [],
      selected_user: null
    }
  },
  computed:{
    current_owner(){
      if(this.returned_tasks['members']){
        return this.returned_tasks['members'].find(member => member.task_owner_type_id === '2');
      }else{
        return [];
      }
    },
    user_id(){
      return this.$store.getters.user_id;
    }
  },
  mounted(){
    this.getUsers();
    console.log("This task");
    console.log(this.returned_tasks);
    console.log("This task end");
  },
  setup(){
    const router = useRouter()
    let token = this.$store.getters.token;
    if( token ){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }else{
      router.push({ name: "login"})
    }
    return {
      router
    }
  },
  methods:{
    addUser(){
      axios.post(this.$store.getters.url+'/api/cases/task-owners', {
        action : 'addmember',
        task_id : this.returned_tasks.id,
        user_id : this.selected_user.id
      }).then(() => {
          this.referModalVisible = false;
          this.$emit('reload');
      })
    },
    removeMember(member){
      axios.post(this.$store.getters.url+'/api/cases/task-owners',{
        task_id : this.returned_tasks.id,
        user_id : member.user_id,
        action: 'delete'
      }).then(() => {
        Notify.create({
          progress: true,
          timeout: 1000,
          position: 'center',
          color: 'green-4',
          textColor: 'white',
          icon: 'cloud_done',
          message: 'Member Removed !! '
        })
        this.$emit('reload');
      })
    },
    acceptTask(){
      axios.post(this.$store.getters.url+'/api/cases/task-owners', {
        task_id : this.returned_tasks.id,
        user_id : this.user_id,
        action: 'accept'
      }).then(() => {
        Notify.create({
          color: 'green-5',
          textColor: 'white',
          position: 'center',
          icon: 'check',
          message: 'Task accepted',
          timeout: 10000,
        })
        this.$emit('reload');
      })
    },
    updateTaskStatus(status) {
        let data = new FormData();
        data = {
          "action": "updateStatus",
          "id": this.returned_tasks.id,
          "status": status,
          "user_id" : this.user_id,
          'note': 'Changed from open to completed'
        }
        let config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }
        axios.put(this.$store.getters.url+'/api/cases/tasks/' + this.returned_tasks.id, data, config)
            .then(() => {
              Notify.create({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'Tasks Status Updated !! '
              })
            })
            .catch((error) => {
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Error accepting tasks: ' + error
              })
            }).finally(() => {
              this.$emit('reload');
          //Perform action in always
        });
    },
    rejectTask(){
      axios.post(this.$store.getters.url+'/api/cases/task-owners', {
        task_id : this.returned_tasks.id,
        user_id : this.user_id,
        action: 'reject'
      }).then(() => {
        Notify.create({
          color: 'green-5',
          textColor: 'white',
          position: 'center',
          icon: 'check',
          message: 'Task accepted',
          timeout: 10000,
        })
        this.$emit('reload');
      })
    },
    followTask(){
      axios.post(this.$store.getters.url+'/api/cases/case-followers', {
        case_id : this.returned_tasks.id,
        user_id : this.selected_user.id
      }).then(() => {
        this.referModalVisible = false;
        this.$emit('reload');
      })
    },
    getUsers(){
      axios.get(this.$store.getters.url+'/api/api-users')
          .then((res) => {
              this.users = res.data.data;
          })
          .catch(() => {

          }).finally(() => {

      });
    },

  }

}
</script>
