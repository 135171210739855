<template>
  <q-dialog v-model="camera_open" persistent>
    <div class="relative flex flex-col items-center justify-center w-full min-h-screen bg-black">
      <!-- Video Element -->
      <video ref="video" autoplay playsinline class="w-full max-h-screen"></video>

      <!-- Capture Buttons -->
      <div class="absolute-bottom min-w-screen justify-items-center q-mb-xl text-center justify-center">

        <q-btn flat round color="white"
               :size="'xl'"
               class="bg-black q-mx-md"
               v-if="isRecording" @click="capturePhoto">
          {{ videoSeconds }}
        </q-btn>
        <q-btn flat round color="white"
               v-if="!isRecording"
               :size="'xl'"
               class="bg-black q-mx-md"
               icon="photo_camera" @click="capturePhoto" />
        <q-btn
            flat
            round
            class="bg-black q-mx-md"
            :size="'xl'"
            :color="isRecording ? 'red' : 'white'"
            :icon="isRecording ? 'stop' : 'videocam'"
            @click="toggleRecording"
        />
        <q-btn
            round
            color="white"
            class="bg-black q-mx-md"
            size="xl"
            icon="flip_camera_android"
            @click="switchCamera"
        />
      </div>

      <!-- Close Camera Button -->
      <div class="absolute-bottom min-w-screen justify-items-center q-mt-md text-center justify-center">
        <q-btn flat round color="white"
               class="bg-black q-mx-md"
               icon="close" @click="closeCamera">
        </q-btn>

      </div>
    </div>
  </q-dialog>
  <q-dialog v-model="voice_open" persistent>
    <div class="relative flex flex-col items-center justify-center w-full min-h-screen bg-black">
      <!-- Capture Buttons -->
      <div class="absolute-bottom min-w-screen justify-items-center q-mb-xl text-center justify-center">
        <q-btn flat round color="white"
               :size="'xl'"
               class="bg-black q-mx-md"
               v-if="this.isVoiceRecording" @click="capturePhoto">
          {{this.voiceSeconds}}
        </q-btn>
        <q-btn
            flat
            round
            class="bg-black q-mx-md"
            :size="'xl'"
            :color="isVoiceRecording ? 'red' : 'white'"
            :icon="isVoiceRecording ? 'stop' : 'mic'"
            @click="toggleVoiceRecording"
        />
      </div>
      <div class="absolute-bottom min-w-screen justify-items-center q-mt-md text-center justify-center">
        <q-btn flat round color="white"
               class="bg-black q-mx-md"
               icon="close" @click="voice_open = false" />
      </div>
    </div>
  </q-dialog>
  <q-dialog  v-model="previewOpen" persistent>
    <q-card class="q-card--bordered bg-white">
      <q-card-section class="row items-center justify-between bg-primary text-white">
        <div class="text-h6">File Preview</div>
        <q-btn flat round icon="close" @click="previewOpen = false" />
      </q-card-section>
      <q-separator />
      <!-- Ensure this section scrolls while keeping the buttons visible -->
      <q-card-section class="scroll-area full-height" style="overflow-y: auto; flex: 1;">
        <template v-if="fileBlob">
          <q-img
              v-if="fileBlob.type.startsWith('image/')"
              :src="createObjectURL(fileBlob)"
              class="q-mb-md"
              style="max-width: 100%; max-height: calc(100vh - 200px);"
          />

          <video
              v-else-if="fileBlob.type.startsWith('video/')"
              :src="createObjectURL(fileBlob)"
              controls
              class="q-mb-md"
              style="width: 100%; height: auto; max-height: calc(100vh - 200px);"
          ></video>

          <audio
              v-else-if="fileBlob.type.startsWith('audio/')"
              :src="createObjectURL(fileBlob)"
              controls
              style="width: 100%;"
          ></audio>
          <p v-else>Unsupported file type</p>
        </template>
      </q-card-section>
      <q-separator />
      <!-- Keep buttons always visible -->
      <q-card-actions align="center" class="q-pa-md">
        <q-btn flat label="Upload" color="primary" @click="uploadFile()" />
        <q-btn flat label="Cancel" color="primary" @click="previewOpen = false" />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-list bordered>
      <q-item>
        <q-item-section class="text-h5">Case: {{this.returned_cases.id}}</q-item-section>
      </q-item>
    <q-item v-if="this.returned_cases.is_closed" class="q-pa-lg gap-4">
      <div class="flex flex-col justify-between text-center flex-1">
          <p class="text-red-7">Cannot add files to closed {{this.docket_description}}</p>
      </div>
    </q-item>
    <q-item v-if="!this.returned_cases.is_closed"  class="q-pa-lg gap-4">
<!--      <div class="flex flex-col justify-between text-center flex-1">-->
<!--        <div class="ml-auto">-->
<!--          <q-btn class="q-ml-md" icon="camera_alt" round size="md" dense @click="openCamera()"></q-btn>-->
<!--        </div>-->
<!--      </div>-->
      <div class="flex flex-col justify-between text-center flex-1">
        <div class="ml-auto">
          <div v-bind="getRootProps()">
            <input v-bind="getInputProps()" />
          </div>
          <q-btn
              class="q-ml-md"
              icon="attachment"
              round
              size="md"
              dense
              @click="open">
          </q-btn>
        </div>
      </div>
      <div class="flex flex-col justify-between text-center flex-1">
        <q-btn
            class="q-ml-md text-black"
            icon="mic"
            round
            size="md"
            dense
            :color="isRecording ? 'red' : 'white'"
            @click="voice_open = true"
        />
      </div>
    </q-item>
  </q-list>
  <q-list>
    <q-item v-for="(media,index) in this.returned_cases.media_folders" :key="index">
      <q-item-section>
        <div class="q-card--bordered q-m-lg" style="display: grid; grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); gap: 16px;" v-for="(media_container,counter) in media.media_containers" :key="counter">
          <q-img class="q-card--bordered q-m-lg" v-if="hasImageExtension(media_container.media[0].original_url)" :src="media_container.media[0].original_url" />
          <q-video
              v-if="hasVideoExtension(media_container.media[0].original_url)"
              :src="media_container.media[0].original_url"
              :autoplay="false"
          />
          <a :href="media_container.media[0].original_url" v-if="!hasImageExtension(media_container.media[0].original_url) && !hasVideoExtension(media_container.media[0].original_url)" name="insert_drive_file">
            {{media_container.original_name}}
          </a>
        </div>
      </q-item-section>
    </q-item>
  </q-list>
</template>


<script>

import axios from "axios";
import {Notify} from "quasar";
import { useDropzone } from "vue3-dropzone";
import { useStore } from 'vuex';
import {  ref } from 'vue';
export default {
  name: 'CaseAttachments',
  props: [
      'returned_cases'
  ],
  emits:[
      'reloadData',
  ],
  data(){
    return {
      voiceSeconds : 0,
      currentCamera: "user", // 'user' for front, 'environment' for back
      videoSeconds : 0,
      voiceTimerInterval : null,
      videoTimerInterval : null,
      photoData : false,
      previewOpen : false,
      voice_open: false,
      camera_open: false,
      isRecording : false,
      isVoiceRecording: false,
      audioBlob : null,
      audioChunks : [],
      videoBlob : null,
      recordedChunks : [],
      videoURL : null,
      fileBlob : null,
    }
  },
  computed: {
    case_id(){
      return this.returned_cases.id
    },
    docket_description(){
      return this.returned_cases.docket_description;
    },
    user_id(){
        return this.$store.getters.user_id;
    }
  },
  setup(props) {
    function onDrop(acceptFiles) {
      console.log(acceptFiles);
      saveFiles(acceptFiles); // saveFiles as callback
    }
    const store = useStore();
    function saveFiles(files) {
      files.forEach((panicPhoto) => {
        console.log(panicPhoto);
        let file_data = new FormData();
        console.log(props.returned_cases);
        file_data.append('object_class','App\\Models\\Docket');
        file_data.append("object_id", props.returned_cases.id);
        file_data.append("folder_id", props.returned_cases.media_folders[0].id);
        file_data.append('images[]', panicPhoto, panicPhoto.name);
        axios.post(store.state.url + '/api/api-media', file_data)
            .then(() => {
              Notify.create({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'File Uploaded'
              })
            })
            .catch((error) => {
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'File FAILED: ' + error
              })
            }).finally(() => {
          //Perform action in always
        });
      });
    }

    let media = ref([]);
    const {getRootProps, getInputProps, ...rest} = useDropzone({onDrop});
    return {
      media,
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  methods:{
    createObjectURL(blob) {
      return blob ? URL.createObjectURL(blob) : "";
    },
    startVoiceTimer() {
      this.voiceSeconds = 0; // Reset the timer
      this.voiceTimerInterval = setInterval(() => {
        console.log(this.voiceSeconds);
        this.voiceSeconds++;
      }, 1000);
    },
    startVideoTimer() {
      this.videoSeconds = 0; // Reset the timer
      this.videoTimerInterval = setInterval(() => {
        this.videoSeconds++;
      }, 1000);
    },
    async toggleVoiceRecording() {
      if (this.isVoiceRecording) {
        // Stop the voice recording
        this.isVoiceRecording = false;
        this.mediaRecorder.stop();
        clearInterval(this.voiceTimerInterval);
      } else {
        try {
          // Access the microphone
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          this.isVoiceRecording = true;

          // Initialize MediaRecorder for audio
          this.mediaRecorder = new MediaRecorder(stream);
          this.audioChunks = [];

          // Collect audio data chunks
          this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              this.audioChunks.push(event.data);
            }
          };

          // Handle the stop event to process the audio file
          this.mediaRecorder.onstop = () => {
            // Create the audio Blob and store it in this.fileBlob
            this.fileBlob = new Blob(this.audioChunks, { type: "audio/webm" });
            this.audioURL = URL.createObjectURL(this.fileBlob);
            console.log("Audio Blob stored:", this.fileBlob);
            this.voice_open = false;
            this.previewOpen = true;

            // Reset audio chunks
            this.audioChunks = [];
          };

          // Start recording
          this.mediaRecorder.start();
          this.startVoiceTimer();
        } catch (error) {
          console.error("Error accessing microphone:", error);
          alert("Unable to access the microphone. Please check your permissions.");
        }
      }
    },
    toggleRecording() {
      if (this.isRecording) {
        // Stop recording and wait for onstop to finish
        this.isRecording = false;
        this.mediaRecorder.stop();
        clearInterval(this.videoTimerInterval);

        // Handle the onstop logic asynchronously
        this.mediaRecorder.onstop = () => {
          // Create a Blob from the recorded chunks and store it in this.fileBlob
          this.fileBlob = new Blob(this.recordedChunks, { type: "video/mp4" });
          this.videoURL = URL.createObjectURL(this.fileBlob);
          console.log("Video Blob stored:", this.fileBlob);
          console.log("Video URL:", this.videoURL);
          this.camera_open = false;
          this.previewOpen = true;
        };
      } else {
        // Start recording
        this.isRecording = true;
        this.startVideoTimer();
        this.recordedChunks = [];
        this.mediaRecorder.start();
        console.log("Recording started...");
      }
    },
    capturePhoto() {
      const video = this.$refs.video;
      console.log("Taking photo");

      if (video && video.srcObject) {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Store the base64 image data
        this.photoData = canvas.toDataURL("image/png");
        console.log("Captured Photo Data URL:", this.photoData);

        // Convert base64 to Blob and store in this.fileBlob
        fetch(this.photoData)
            .then((res) => res.blob())
            .then((blob) => {
              this.fileBlob = blob; // Store the image Blob
              console.log("Photo Blob stored:", this.fileBlob);
              this.closeCamera();
            });

      } else {
        console.error("Camera is not initialized.");
      }
    },
    async uploadAudio() {
      let file_data = new FormData();
      file_data.append('object_class','App\\Models\\Docket');
      file_data.append("object_id", this.returned_cases.id);
      file_data.append("folder_id", this.returned_cases.media_folders[0].id);
      file_data.append('images[]', this.audioBlob, 'audio.webm');
      axios.post(this.$store.getters.url + '/api/api-media', file_data)
          .then(() => {
            Notify.create({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'File Uploaded'
            })
          })
          .catch((error) => {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'File FAILED: ' + error
            })
          }).finally(() => {
      });
    },
    async uploadFile() {
      try {
        if (!this.fileBlob) {
          Notify.create({
            color: 'red-5',
            textColor: 'white',
            icon: 'warning',
            message: 'No file selected'
          });
          return;
        }

        // Determine file extension based on the blob type
        let fileName = 'upload';
        if (this.fileBlob.type.startsWith('audio/')) {
          fileName += '.webm';
        } else if (this.fileBlob.type.startsWith('video/')) {
          fileName += '.mp4';
        } else if (this.fileBlob.type.startsWith('image/')) {
          fileName += '.png';
        } else {
          fileName += '.dat';
        }

        let file_data = new FormData();
        file_data.append('object_class', 'App\\Models\\Docket');
        file_data.append('object_id', this.returned_cases?.id || '');
        file_data.append('folder_id', this.returned_cases?.media_folders?.[0]?.id || '');
        file_data.append('images[]', this.fileBlob, fileName);

        let response = await axios.post(
            `${this.$store.getters.url}/api/api-media`,
            file_data,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );

        if (response.status === 200) {
          Notify.create({
            color: 'green-4',
            textColor: 'white',
            icon: 'cloud_done',
            message: 'File Uploaded Successfully'
          });
          this.previewOpen = false;
          this.fileBlob = null;
        } else {
          throw new Error('Upload failed with status: ' + response.status);
        }
      } catch (error) {
        console.error("Upload Error:", error);
        Notify.create({
          color: 'red-5',
          textColor: 'white',
          icon: 'warning',
          message: 'File Upload Failed: ' + (error.response?.data?.message || error.message)
        });
      }
    },
    async openCamera() {
      this.camera_open = true;
      this.$nextTick(async () => {
        const video = this.$refs.video;
        try {
          // Stop existing stream if already running
          if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
          }

          // Request camera access with the current camera preference
          this.stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: this.currentCamera },
            audio: true
          });

          video.srcObject = this.stream;

          // Prepare MediaRecorder for video recording
          this.mediaRecorder = new MediaRecorder(this.stream);
          this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              this.recordedChunks.push(event.data);
            }
          };
          this.mediaRecorder.onstop = () => {
            this.videoBlob = new Blob(this.recordedChunks, { type: "video/mp4" });
            this.videoURL = URL.createObjectURL(this.videoBlob);
          };
        } catch (error) {
          console.error("Error accessing camera:", error);

          if (error.name === "NotAllowedError") {
            alert("Camera access was denied. Please enable camera permissions.");
          } else if (error.name === "NotFoundError") {
            alert("No camera found on this device.");
          } else if (error.name === "OverconstrainedError") {
            alert("Your device cannot fulfill the constraints. Ensure your camera supports the requested resolution.");
          } else {
            alert(error);
          }

          // Close the camera dialog if initialization fails
          this.camera_open = false;
        }
      });
    },
    async getCameras() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      return devices.filter(device => device.kind === "videoinput");
    },
    async switchCamera() {
      const cameras = await this.getCameras();
      if (cameras.length < 2){
        return;
      }

      // Toggle between the first and second camera
      this.currentCameraIndex = this.currentCameraIndex === 0 ? 1 : 0;

      // Stop the current stream before switching
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
      }

      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { exact: cameras[this.currentCameraIndex].deviceId } },
          audio: true
        });

        document.querySelector("video").srcObject = this.stream;
      } catch (error) {
        console.error("Error switching camera:", error);
      }
    },
    closeCamera() {
      this.camera_open = false;
      const video = this.$refs.video;
      if (video && video.srcObject) {
        const stream = video.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
        video.srcObject = null;
      }

      // Reset states
      this.photoData = null;
      this.isRecording = false;
      this.recordedChunks = [];
      this.videoBlob = null;
      this.videoURL = null;
      if(this.fileBlob !== null){
        this.previewOpen = true;
      }
    },
    hasImageExtension(path){
      return path.match(/\.(jpeg|jpg|gif|png)$/) != null;
    },
    hasVideoExtension(path){
      return path.match(/\.(mp4|webm|ogg)$/) != null;
    },
  }
}
</script>

<style>
.camera-container {
  z-index: 10; /* Higher than the footer */
}
video {
  /* This will make the video fill the element's dimensions, possibly cropping it */
  object-fit: cover;

  /* Use the entire viewport width and height */
  width: 100vw;
  height: 100vh;

  /* Optional: Center the video in its container */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
